import { useAppDispatch, useAppSelector } from '@/app'
import { SignInView } from '@/features/auth/SignInView'
import { useAuthentication } from '@alexandrainst/plana-react-api'
import { FC, PropsWithChildren, ReactNode, useState } from 'react'
import { z } from 'zod'
import { selectAuth, actions } from '@/features/auth'
import { useBiometricPasswordStorage } from './biometric-password-storage'
import { useQueryClient } from '@tanstack/react-query'
import { useServerLog } from '@/api'
import { useInstallation } from '@/features/app-information'
import { useOpenFeatureClient } from '@/incubator/featureFlags/FeatureFlagProvider'

type SignInFlowProps = {
  children: ReactNode
  onResetBackend: () => void
  onLogin: (username: string, password: string) => Promise<void>
  onLoginWithBiometrics: () => Promise<void>
  onSetUserChoice: (useBiometricSecret: boolean) => void
  isAuthenticated: boolean
  loginRequestInProgress: boolean
  hasBiometricFeature: boolean
  userHasMadeBiometricChoice: boolean
  useBiometricSecret: boolean
  hasStoredBiometricSecret: boolean
  canStoreBiometricSecret: boolean
}

export const SignInFlow = ({
  children,
  onResetBackend,
  onLogin,
  onLoginWithBiometrics,
  onSetUserChoice,
  isAuthenticated,
  loginRequestInProgress,
  hasBiometricFeature,
  userHasMadeBiometricChoice,
  useBiometricSecret,
  hasStoredBiometricSecret,
  canStoreBiometricSecret,
}: SignInFlowProps) => {
  if (isAuthenticated) {
    if (hasBiometricFeature && canStoreBiometricSecret) {
      if (
        !userHasMadeBiometricChoice ||
        (!hasStoredBiometricSecret && useBiometricSecret)
      ) {
        return (
          <SignInView
            loginRequestInProgress={loginRequestInProgress}
            onSignIn={onLogin}
            onReset={onResetBackend}
            showRemember={false}
            showBiometricsButton={false}
            showBiometricsPrompt={true}
            onSetBiometricsChoice={onSetUserChoice}
          />
        )
      }
    }
    return <>{children}</>
  }

  if (
    hasBiometricFeature &&
    userHasMadeBiometricChoice &&
    useBiometricSecret &&
    hasStoredBiometricSecret
  ) {
    return (
      <SignInView
        loginRequestInProgress={loginRequestInProgress}
        onSignIn={onLogin}
        onReset={onResetBackend}
        showRemember={false}
        showBiometricsButton={true}
        onSigninWithBiometrics={onLoginWithBiometrics}
      />
    )
  }

  return (
    <SignInView
      loginRequestInProgress={loginRequestInProgress}
      onSignIn={onLogin}
      onReset={onResetBackend}
      showRemember={false}
    />
  )
}

export const credsSchema = z.object({
  username: z.string(),
  password: z.string(),
})

export const SignInFlowWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { installed } = useInstallation()
  const { info } = useServerLog()
  const [creds, setCreds] = useState(
    credsSchema.parse({ username: '', password: '' })
  )

  const { getSecret, hasBiometricFeature } = useBiometricPasswordStorage()
  const dispatch = useAppDispatch()
  const {
    login,
    logout,
    state: { authenticated, loginRequestInProgress },
  } = useAuthentication()
  const {
    biometricSecretId,
    processing,
    usersBiometricSecretChoice,
    userHasMadeBiometricChoice,
  } = useAppSelector(selectAuth)
  const flagClient = useOpenFeatureClient()

  const client = useQueryClient()

  const resetBackend = () => {
    dispatch({ type: 'store/reset' })
    client.clear()
    logout()
    window.localStorage.clear()
  }

  const onSignin = async (username: string, password: string) => {
    setCreds(credsSchema.parse({ username, password }))
    dispatch(actions.biometricSecretStored(''))
    await login(username, password)
  }

  const loginWithBiometrics = async () => {
    const secret = credsSchema.parse(
      JSON.parse((await getSecret(biometricSecretId))[0])
    )
    console.info('Fetched secret', secret)
    login(secret.username, secret.password)
  }

  const handleOnSetUserChoice = (choice: boolean) => {
    if (choice) {
      info('User selected to use biometrics')
      dispatch(actions.storeBiometricSecret(JSON.stringify(creds)))
    } else {
      info('User selected not to use biometrics')
    }
    dispatch(actions.useBiometrics(choice))
  }
  const allowBiometricSignin = flagClient.getBooleanValue(
    'allowBiometricSignin',
    true
  )
  const shouldOfferBiometricSigning =
    hasBiometricFeature && installed && allowBiometricSignin

  return (
    <SignInFlow
      onLoginWithBiometrics={loginWithBiometrics}
      onLogin={onSignin}
      onResetBackend={resetBackend}
      onSetUserChoice={handleOnSetUserChoice}
      hasBiometricFeature={shouldOfferBiometricSigning}
      hasStoredBiometricSecret={biometricSecretId !== ''}
      isAuthenticated={authenticated}
      loginRequestInProgress={loginRequestInProgress || processing !== 'idle'}
      useBiometricSecret={usersBiometricSecretChoice}
      userHasMadeBiometricChoice={userHasMadeBiometricChoice}
      canStoreBiometricSecret={creds.username !== '' && creds.password !== ''}
    >
      {children}
    </SignInFlow>
  )
}
